import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import {
  FvButton,
  Icon,
  TextAreaField,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { type OpportunityDetailsDto } from '@fv/models'
import { supportMessage } from '@/constants'

import { type Opportunity } from '../../types/Opportunity'
import {
  AdditionalTruckInfoFields,
  AdditionalTruckInfoToggle,
} from '../opp-details/AdditionalTruckInfoForm'
import { useUpdateOppDetails } from '../opp-details/mutations'
import { useConfirmLoad } from './hooks/useConfirmLoad'
import { useRejectLoad } from './hooks/useRejectLoad'

type Props = {
  opportunity: Opportunity
}

export const ConfirmationForm = ({ opportunity }: Props) => {
  const [showTruckInfo, toggleTruckInfo] = useToggle()
  const { loadId, status } = opportunity
  const formRef = useRef()
  const confirmLoad = useConfirmLoad()
  const updateLoadDetails = useUpdateOppDetails()
  const [details, setDetails] = useState<OpportunityDetailsDto>({})
  const rejectLoad = useRejectLoad()
  const disabled = confirmLoad.isLoading || rejectLoad.isLoading

  const [values, setValues] = useState({
    message: '',
    pickupNumber: '',
  })

  async function onValidSubmit() {
    if (disabled) return

    await confirmLoad
      .mutateAsync({
        loadId,
        message: values.message,
        pickupNumber: values.pickupNumber,
      })
      .catch(() => {
        toast.error(`Unable to confirm load, ${supportMessage}`)
      })

    if (Object.values(details).some(v => !!v)) {
      await updateLoadDetails.mutateAsync({
        loadId,
        details,
      })
    }
  }

  function rejectAwardedLoad() {
    if (disabled) return

    if (
      window.confirm(
        'By rescinding your winning bid, you acknowledge you will no longer be taking this freight. The shipper will be notified and you will not be assigned to this particular load.',
      )
    ) {
      rejectLoad.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline load, ${supportMessage}`)
      })
    }
  }

  return (
    <ValidatedForm onValidSubmit={onValidSubmit} ref={formRef}>
      <div className="shipment-item-ancillary">
        <div className="grid grid-cols-12 gap-x-2 mt-4 b550:mb-4 b550:[&>*]:col-span-12">
          <div className="form-group col-span-4">
            <label htmlFor="" className="label">
              Confirmation
              <span className="text-fv-orange ml-1">*</span>
            </label>
            <div className="input-group input-group--flex ">
              <div className="input-group-addon">
                <Icon icon="hashtag" className="color-dark" />
              </div>
              <TextField
                className="form-control"
                disabled={disabled || status === 'confirmed'}
                maxLength={50}
                name="pickupNumber"
                onChange={e => {
                  setValues(prev => ({
                    ...prev,
                    pickupNumber: e.target.value,
                  }))
                }}
                required
                type="text"
                value={values.pickupNumber}
              />
            </div>
          </div>

          <div className="form-group col-span-8 mb-0">
            <label htmlFor="" className="label">
              Message
            </label>
            <TextAreaField
              className="form-control"
              disabled={disabled}
              maxLength={500}
              name="message"
              onChange={e => {
                setValues(prev => ({
                  ...prev,
                  message: e.target.value,
                }))
              }}
              placeholder="Include an optional message here."
              value={values.message}
            />
          </div>
        </div>
        {showTruckInfo && (
          <div className="flex items-end gap-x-2 mb-6">
            <AdditionalTruckInfoFields value={details} onChange={setDetails} />
          </div>
        )}

        <div className="flex gap-x-4 b550:gap-x-1 mt-2">
          <AdditionalTruckInfoToggle
            value={showTruckInfo}
            onToggle={toggleTruckInfo}
          />
          <div className="border-t h-px border-dotted flex-1 border-fv-gray" />
          <div className="ml-auto flex items-center">
            <FvButton
              disabled={disabled}
              onClick={rejectAwardedLoad}
              type="button"
              theme="default"
              loading={rejectLoad.isLoading}
              icon="ban"
            >
              <span>Decline</span>
            </FvButton>

            <FvButton
              type="submit"
              disabled={disabled}
              theme="default"
              loading={confirmLoad.isLoading || updateLoadDetails.isLoading}
              icon={'shield-check'}
              iconClass={'color-success'}
            >
              <span>Confirm</span>
            </FvButton>
          </div>
        </div>
      </div>
    </ValidatedForm>
  )
}
