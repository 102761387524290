import { Icon, Tooltip } from '@fv/client-components'
import { usePrettyNumber } from '@/hooks/usePrettyNumber'

type SelectedPriceDisplayProps = {
  amount: number
  currency: string
  isLowest: boolean
  showComparison: boolean
}

export const SelectedPriceDisplay = ({
  amount,
  currency,
  isLowest,
  showComparison,
}: SelectedPriceDisplayProps) => {
  const prettyNumber = usePrettyNumber()

  const { dollars, cents } = dollarsAndCents({
    amount,
    prettyNumber,
  })

  return (
    <div className="flex">
      <span className="flex items-start text-[1.65rem] font-mono">
        $<span className="dollars">{dollars}</span>
        <div className="cents">{cents}</div>
        <div className="price__currency">{currency.toUpperCase()}</div>
      </span>
    </div>
  )
}

function dollarsAndCents({
  amount,
  prettyNumber,
}: {
  amount: number
  prettyNumber: (value: string) => string
}) {
  const [dollars, cents] = String(amount?.toFixed(2)).split('.')

  return {
    dollars: dollars ? prettyNumber(dollars) : '0',
    cents: cents ? cents.padEnd(2, '0') : '00',
  }
}
