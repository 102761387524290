import { type ChangeEvent, type ForwardedRef, forwardRef } from 'react'

import { FvButton } from '@fv/client-components'
import { currencyToString, parseCurrency } from '@fv/client-core'
import { InputGroup, InputGroupAddon } from '@/components/inputs/InputGroup'
import { PriceInput } from '@/components/inputs/PriceInput'

import { useQuotesFormActions, useQuotesFormState } from './QuotesFormProvider'
import { type Model } from './types'

type Props = {
  disabled?: boolean
  required?: boolean
  model: Model
  onChange: (value: Model) => void
  isContractedRate?: boolean
}
export const BaseRateField = forwardRef(
  (
    { model: model, required, disabled, onChange, isContractedRate }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { contractedRatesLocked } = useQuotesFormState()
    const { toggleContractedRateLock } = useQuotesFormActions()
    const { charges, quoteId, amount } = model
    const isEditingCharge = charges?.length
    const charge = isEditingCharge
      ? model.charges?.find(c => c.name === 'linehaul')
      : undefined
    const value = isEditingCharge ? charge?.amount : amount

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const amount = parseCurrency(e.target.value)
      onChange({
        ...model,
        ...(isEditingCharge
          ? {
              charges: model.charges?.map(c =>
                c.name === charge.name ? { ...c, amount } : c,
              ),
            }
          : { amount }),
      })
    }
    return (
      <InputGroup className="input-group--flex mb-0">
        <InputGroupAddon
          tooltip="You have the option to input either an 'all-in' rate here or a base rate along with itemized charges."
          icon="question-circle"
          iconClassName="text-sm text-fv-orange"
          transform="up-1"
        />
        <InputGroupAddon icon="dollar-sign" iconClassName="color-dark" />
        <PriceInput
          className="form-control w-[5rem] text-right"
          disabled={disabled}
          name={`${quoteId}_amount`}
          value={value ? currencyToString(value) : ''}
          required={required}
          onChange={handleChange}
          ref={ref}
        />
        {isContractedRate && (
          <FvButton
            theme="default"
            className="-ml-1"
            icon={contractedRatesLocked ? 'lock' : 'times'}
            onClick={toggleContractedRateLock}
          />
        )}
      </InputGroup>
    )
  },
)
