import { logoutSession } from '@fv/client-core'
import { apiUri } from '@/constants'
import { useUserContext } from '@/hooks/settings'

import { ProfileForm } from './ProfileForm'
import { Sessions } from './Sessions'

const revokeSession = (sessionId: string | null) => {
  logoutSession(sessionId, apiUri)
}

export const SettingsPage = () => {
  const context = useUserContext()

  return (
    <main className="col-span-3 row-span-2">
      <header className="header-divider">Settings</header>
      <ProfileForm className="mb-8 max-w-[60rem]" />
      <Sessions
        revokeSession={revokeSession}
        sessions={context.data?.user?.sessions ?? []}
      />
    </main>
  )
}
