import clsx from 'clsx'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useToggle } from 'usehooks-ts'

import { FvButton, Icon, Tooltip } from '@fv/client-components'
import { supportMessage } from '@/constants'

import { type Opportunity } from '../../types/Opportunity'
import { getBidExpiration } from '../../utils/getBidExpiration'
import {
  AdditionalTruckInfoForm,
  AdditionalTruckInfoToggle,
} from '../opp-details/AdditionalTruckInfoForm'
import {
  useOppListingActions,
  useOppListingState,
} from '../opportunities/OppListingContextProvider'
import {
  type OppListingStatus,
  type OppPage,
  oppPageStatusMap,
} from '../opportunities/types'
import { QuotesForm } from '../quote/QuotesForm'
import { QuotesFormProvider } from '../quote/QuotesFormProvider'
import { useArchiveOpportunity } from './hooks/useArchiveOpportunity'
import { useRejectLoad } from './hooks/useRejectLoad'
import { CardActions } from './CardActions'
import { ConfirmationForm } from './ConfirmationForm'
import { ExpirationNotice } from './ExpirationNotice'
import { ShipmentDetails } from './ShipmentDetails'
import { ShipperCanceledOverlay } from './ShipperCanceledOverlay'

type Props = {
  isActive: boolean
  opportunity: Opportunity
}

const getPageFromStatus = (status: OppListingStatus): OppPage => {
  return Object.entries(oppPageStatusMap).find(([, statuses]) =>
    [...statuses].includes(status),
  )?.[0] as OppPage
}

const bidExpirationStatuses: OppListingStatus[] = ['offered', 'open']
export const OppCard = ({ isActive, opportunity }: Props) => {
  const { setActiveId } = useOppListingActions()
  const { status, page: storePage } = useOppListingState()
  const { bidExpirationDate, isExpired } = getBidExpiration(opportunity)
  const oppStatus = opportunity.status
  const [showQuoteForm, toggleQuoteForm, setShowQuoteForm] = useToggle()
  const [showTruckInfo, toggleTruckInfo] = useToggle()
  // 90% of the time page will not change depending on the status, so keep this in the store for cacheability
  const page =
    storePage === 'search' || storePage === 'direct'
      ? getPageFromStatus(oppStatus)
      : storePage

  const { isArchived, loadId } = opportunity
  const rejectLoad = useRejectLoad()
  const archive = useArchiveOpportunity()
  const archiveIcon = isArchived ? 'trash-restore-alt' : 'archive'
  function archiveQuoteRequest(shouldArchive: boolean) {
    if (archive.isLoading || rejectLoad.isLoading) return

    archive.mutateAsync({ archive: shouldArchive, loadId }).catch(() => {
      toast.error(`Unable to archive shipment, ${supportMessage}`)
    })
  }
  function rejectActiveLoad() {
    if (archive.isLoading || rejectLoad.isLoading) return

    if (
      window.confirm(
        "By clicking 'ok' you are acknowledging that you can no longer take this load even though you have already confirmed with the shipper. We will notify the shipper in Freightview and via email.",
      )
    ) {
      rejectLoad.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline load, ${supportMessage}`)
      })
    }
  }
  useEffect(() => {
    if (showQuoteForm && !isActive) setShowQuoteForm(false)
  }, [isActive, showQuoteForm, setShowQuoteForm])
  return (
    <article
      className={clsx('shipment-item opacity-80', {
        'shipment-item--selected border-fv-orange b1000:border-r border-r-0 !opacity-100':
          isActive,
        'pb-10': page === 'active',
        'pb-6': page !== 'active',
      })}
      onClick={() => setActiveId(opportunity.loadId)}
    >
      {bidExpirationStatuses.includes(status) && bidExpirationDate && (
        <ExpirationNotice isActive={isActive} page={'opportunity'}>
          {isExpired ? 'Bidding expired on' : 'Bidding expires on '}{' '}
          {bidExpirationDate}
        </ExpirationNotice>
      )}
      <div
        className={clsx(
          'shipment-item__initial relative',
          !opportunity?.isLowest && !!opportunity?.lowestBid && 'pt-3',
        )}
      >
        <div className="absolute -left-6 -top-6 flex z-10">
          {((opportunity?.showPricingIndicator &&
            !!opportunity?.lowestBid &&
            !!opportunity?.isLowest) ||
            (opportunity?.showPricingIndicator &&
              !!opportunity?.cheapestQuoteAmount)) && (
            <div
              className={`flex relative z-10 items-center justify-center h-8 w-8 rounded-full border-2 bg-white ${opportunity?.isLowest ? 'border-[#99d87a] !bg-green-100' : 'border-red-400 !bg-red-100'}`}
            >
              <Icon
                className={`text-[1rem] ${opportunity?.isLowest ? 'text-[#99d87a]' : 'text-red-400'}`}
                icon={opportunity?.isLowest ? 'arrow-down' : 'arrow-up'}
              />
            </div>
          )}
          {!opportunity?.isLowest && !!opportunity?.lowestBid && (
            <div className="bg-red-100 text-inherit pr-2 pl-5 leading-none py-[.35rem] border border-red-300 -ml-4 rounded-md text-xs italic">
              Current lowest bid - $
              {opportunity?.lowestBid.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{' '}
              {opportunity?.lowestBidCurrency.toUpperCase()}
            </div>
          )}
        </div>

        {page !== 'open' && <CardActions opportunity={opportunity} />}
        <ShipmentDetails opportunity={opportunity} />
        {page === 'open' && (
          <CardActions
            opportunity={opportunity}
            onToggleQuoteForm={toggleQuoteForm}
          />
        )}
      </div>
      {isActive && oppStatus === 'awarded' && (
        <ConfirmationForm opportunity={opportunity} />
      )}
      {oppStatus === 'confirmed' && (
        <div className="mt-6">
          <AdditionalTruckInfoToggle
            value={showTruckInfo}
            onToggle={toggleTruckInfo}
          />
        </div>
      )}
      {showTruckInfo && oppStatus !== 'awarded' && (
        <AdditionalTruckInfoForm
          opportunity={opportunity}
          className="mt-4 pt-4 border-fv-gray border-t border-dashed"
          onSubmit={toggleTruckInfo}
        />
      )}

      {(showQuoteForm ||
        (isActive && page === 'quoting' && status !== 'lost')) && (
        <QuotesFormProvider opportunity={opportunity}>
          <QuotesForm
            opportunity={opportunity}
            hasQuoted={
              page === 'quoting' || page === 'won' || page === 'active'
            }
          />
        </QuotesFormProvider>
      )}
      {page === 'active' && !showTruckInfo && !showQuoteForm && (
        <div
          className={`absolute bottom-4 flex divide-x divide-fv-gray [&>*]:px-2 ${
            isActive ? 'right-8' : 'right-2'
          }`}
        >
          <Tooltip label={'Manage charges'}>
            <FvButton
              onClick={toggleQuoteForm}
              icon="money-check-edit-alt"
              fw
            />
          </Tooltip>
          <Tooltip
            label={
              opportunity.isArchived ? 'Move back to active queue' : 'Archive'
            }
          >
            <FvButton
              className="m-0"
              onClick={() => archiveQuoteRequest(!isArchived)}
              icon={archiveIcon}
              fw
              loading={archive.isLoading}
            />
          </Tooltip>

          <Tooltip label="Decline this load">
            <FvButton
              className="m-0"
              loading={rejectLoad.isLoading}
              icon="ban"
              onClick={rejectActiveLoad}
              fw
            />
          </Tooltip>
        </div>
      )}

      {opportunity.isCanceled && (
        <ShipperCanceledOverlay
          canArchive={!opportunity.isArchived}
          opportunity={opportunity}
        />
      )}
    </article>
  )
}
