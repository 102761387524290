import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import {
  Icon,
  type IconName,
  type IconProps,
  Tooltip,
} from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

type Props = PropsWithClassName<PropsWithChildren>
export const InputGroup = ({ children, className }: Props) => {
  return <div className={clsx(className, 'input-group')}>{children}</div>
}

type AddonProps = Pick<IconProps, 'transform'> & {
  icon: IconName
  tooltip?: string
  iconClassName?: string
}
export const InputGroupAddon = ({
  icon,
  tooltip,
  iconClassName,
  transform,
}: AddonProps) => {
  return (
    <div className="input-group-addon whitespace-nowrap flex items-center">
      <Tooltip label={tooltip}>
        <Icon icon={icon} className={iconClassName} transform={transform} />
      </Tooltip>
    </div>
  )
}
