import { useQueryClient } from '@tanstack/react-query'

import { type InfiniteQueryData, setDataByPage } from '@fv/client-core'
import { opportunitySorts } from '@fv/models'
import { coerceArray } from '@fv/models/core'
import {
  type OppListingStatus,
  oppListingStatuses,
} from '@/features/opportunities/types'
import {
  bucketCountKeys,
  type OppListFilterKeyParams,
  opportunityQueryKeys,
  quoteRequestKeys,
} from '@/queryKeys'

import { type Opportunity } from '../../types/Opportunity'

const sortDirs = [1, -1] as (-1 | 1)[]
const queryKeyCombos = oppListingStatuses.flatMap<OppListFilterKeyParams>(
  status =>
    opportunitySorts.flatMap<OppListFilterKeyParams>(sortBy =>
      sortDirs.flatMap<OppListFilterKeyParams>(sortDirection => ({
        status,
        sortBy,
        sortDirection,
      })),
    ),
)
export const useCacheHelpers = () => {
  const queryClient = useQueryClient()

  const updateOppCaches = (
    opp: Opportunity,
    statuses?: OppListingStatus | OppListingStatus[],
  ) => {
    const statusArr = coerceArray(statuses ?? [])
    const keyFilters = statusArr.length
      ? queryKeyCombos.filter(k => statusArr.includes(k.status))
      : queryKeyCombos
    const accountId = opp.shipper.accountId

    const allFilters: OppListFilterKeyParams[] = [
      ...keyFilters,
      ...keyFilters.map(k => ({ ...k, accountId })),
    ]

    allFilters.forEach(filter => {
      let found = false
      const key = opportunityQueryKeys.byFilter(filter)
      queryClient.setQueryData<InfiniteQueryData<Opportunity>>(
        key,
        setDataByPage(prev => {
          if (
            (filter.status === 'archived' && !opp.isArchived) ||
            (filter.status === 'confirmed' && opp.isArchived) ||
            opp.status !== filter.status
          ) {
            found = true
            return prev?.filter(p => p.loadId !== opp.loadId)
          }
          const updates = prev?.map(p => {
            if (p.loadId === opp.loadId) {
              found = true
              return opp
            }
            return p
          })

          return updates
        }),
      )

      if (!found) {
        // if we didn't find the opp in the cache that we expected to
        // then we need to clear that cache because we don't want to
        // compute where the opp should be inserted within that cache
        queryClient.invalidateQueries(key)
      }
      queryClient.invalidateQueries(quoteRequestKeys.id(opp.quoteRequestId))
      queryClient.invalidateQueries(opportunityQueryKeys.search())
    })
  }

  const refreshBucketCounts = () => {
    // This could probably be improved to only refresh "All shippers" or "this shipper" and not "that shipper"
    // but our query keys need to be modified to do that.
    queryClient.invalidateQueries(bucketCountKeys.all)
  }

  return {
    updateOppCaches,
    refreshBucketCounts,
  }
}
