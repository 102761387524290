import { type OpportunityDetailsDto } from '@fv/models'
import { type Opportunity } from '@/types/Opportunity'

export const createDetailModelFromOpp = (
  opp: Opportunity,
): OpportunityDetailsDto => {
  return {
    carrierName: opp.carrierName,
    trackingNumber: opp.track?.trackingNumber,
    sealNumber: opp.refNums?.find(r => r.type === 'seal-num')?.value,
    trailerNumber: opp.refNums?.find(r => r.type === 'trailer-num')?.value,
  }
}
