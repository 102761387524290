import { type ShipmentLocation } from '@fv/client-types'

type Props = Pick<ShipmentLocation, 'refNums'>
export const RefNumbers = ({ refNums }: Props) => {
  const relevantRefNums = refNums?.filter(r => r.type === 'other')
  if (!relevantRefNums?.length) return null

  return (
    <span className="text-xs">
      Ref#: {relevantRefNums.map(n => n.value).join(' / ')}
    </span>
  )
}
