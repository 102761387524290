export const parseCurrency = (value: string | number): number => {
  return Number(parseFloat(value.toString()).toFixed(2))
}

export const currencyToString = (
  value: number,
  includeEmptyDecimals = false,
) => {
  const str = value.toFixed(2)
  if (str.endsWith('.00') && !includeEmptyDecimals) return value.toString()
  return str
}

const emptyDecimalRegex = /\.00$/
type FormatterOpts = {
  currency?: boolean
  includeEmptyDecimals?: boolean
  precision?: number
}
export const useNumberFormatter = ({
  currency,
  includeEmptyDecimals,
  precision = 2,
}: FormatterOpts) => {
  // TODO - replace with locale & currency as we expand
  const formatter = new Intl.NumberFormat('en-US', {
    style: currency ? 'currency' : 'decimal',
    ...((includeEmptyDecimals || precision === 0) && {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }),
    ...(currency && {
      currency: 'USD',
    }),
  })
  return (val: number | string) => {
    const formatted = formatter.format(parseFloat(val.toString()))
    if (formatted.endsWith('.00') && !includeEmptyDecimals) {
      return formatted.replace(emptyDecimalRegex, '')
    }
    return formatted
  }
}
