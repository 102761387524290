import { getEquipmentLabel } from '@fv/client-core'
import { useTypeNames } from '@/hooks/useTypeNames'
import { type Opportunity } from '@/types/Opportunity'

type Props = {
  opportunity: Opportunity
}
export const QuotingDescription = ({ opportunity }: Props) => {
  const { targetPrice } = opportunity
  const { alternateTypes, type } = opportunity.equipmentRequested

  const { equipmentName } = useTypeNames()
  const equipmentLabel = getEquipmentLabel({
    altEquipmentNames: alternateTypes?.map(equipmentName),
    equipmentName: equipmentName(type),
  })
  return (
    <div className="flex items-center gap-x-4 border-b border-dashed border-slate-300 pb-2">
      <p className="">
        You are quoting a {equipmentLabel}{' '}
        {targetPrice && (
          <>
            / Shipper target price is{' '}
            <span className="font-bold">${targetPrice}</span>
          </>
        )}
      </p>
    </div>
  )
}
