import { type ForwardedRef, forwardRef } from 'react'

import { AmountField } from '@fv/client-components'

import { type InputProps } from './types'

export const PriceInput = forwardRef(
  (props: InputProps, ref?: ForwardedRef<HTMLInputElement | null>) => {
    return <AmountField ref={ref} isPrice {...props} />
  },
)
