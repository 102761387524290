import clsx from 'clsx'
import { useState } from 'react'

import {
  ManualTrackForm,
  SelectField,
  SliderPanel,
} from '@fv/client-components'
import { buildTrackStatusOptions, getCurrentSequence } from '@fv/client-core'
import { useTypeNames } from '@/hooks/useTypeNames'

import { type Opportunity } from '../../types/Opportunity'
import { useUpdateTrackStatus } from './hooks/useUpdateTrackStatus'

interface props {
  opportunity: Opportunity
}

export const MultiStopTrackingList = ({ opportunity }: props) => {
  const {
    bol,
    equipmentRequested,
    loadId,
    locations,
    pickup,
    quotedEquipmentType,
    refNums,
    track,
  } = opportunity

  const { equipmentName } = useTypeNames()
  const [nextSequence, setNextSequence] = useState('')
  const closePanel = () => setNextSequence('')
  const updateTrackStatus = useUpdateTrackStatus(loadId)
  const currentSequence = getCurrentSequence(track)
  const refNumbers = refNums?.filter(n => n.value !== bol?.bolNumber)
  const trackSequence = nextSequence || String(currentSequence)
  const equipmentLabel = equipmentName(
    quotedEquipmentType ?? equipmentRequested.type,
  )

  return (
    <>
      <li className="select-status-list-item">
        <SelectField
          className={clsx(
            'form-control',
            track?.currentStatus,
            'form-control--select',
            'form-control--select--status',
          )}
          name="trackSequence"
          onChange={e => setNextSequence(e.target.value)}
          options={buildTrackStatusOptions(locations)}
          value={trackSequence}
        />
      </li>

      <SliderPanel
        closePanel={closePanel}
        isOpen={!!nextSequence}
        width="35rem"
      >
        <p className="mb-0">
          BOL# {bol?.bolNumber ?? 'NA'} / Confirmation#{' '}
          {pickup?.confirmationNumber ?? 'NA'} / {equipmentLabel}
          <br />
          {!!refNumbers.length && (
            <span className="text-xs">
              Ref#: {refNumbers.map(n => n.value).join(' / ')}
            </span>
          )}
        </p>
        <hr className="mb-4" />
        <ManualTrackForm
          closeForm={closePanel}
          initialSequence={Number(nextSequence)}
          loading={updateTrackStatus.isLoading}
          locations={locations}
          onSave={updateTrackStatus.mutate}
        />
      </SliderPanel>
    </>
  )
}
