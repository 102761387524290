import sum from 'lodash/sum'

import { generateString } from '@fv/client-core'
import { type Quote, type SelectFieldOption } from '@fv/client-types'
import { type UpsertOpportunityQuoteDto } from '@fv/models'
import { useAppSettings } from '@/hooks/settings'
import { useTypeNames } from '@/hooks/useTypeNames'
import { type Opportunity } from '@/types/Opportunity'

import { useQuotesFormState } from './QuotesFormProvider'
import { type ChargeModel, emptyCharge, type Model } from './types'

export const createQuoteEditModel = (quote: Quote): Model => ({
  amount: quote.amount,
  currency: quote.currency,
  equipmentType: quote.equipmentType,
  quoteNum: quote.quoteNum || '',
  quoteId: quote._id,
  charges: quote.charges?.map(c => generateCharge(c)),
})

export const useEquimentTypeOptions = (
  opp: Opportunity,
): SelectFieldOption[] => {
  const { equipmentName } = useTypeNames()
  const { type, alternateTypes } = opp.equipmentRequested
  return [
    {
      value: type,
      text: equipmentName(type),
    },
    ...(alternateTypes?.map(alt => ({
      text: equipmentName(alt),
      value: alt,
    })) ?? []),
  ]
}

export const useCanEditQuotes = (opp: Opportunity, quotes: Quote[]) => {
  const { data: settings } = useAppSettings()
  const { contractedRatesLocked } = useQuotesFormState()
  const hasApiRate = quotes.some(q => q.method === 'api')
  const hasContractedRate = quotes.some(q => q.method === 'upload')
  const hasCarrierRate = quotes.some(
    q => q.method === 'manual' && q.source === 'carrier',
  )

  return (
    hasCarrierRate ||
    (hasApiRate && settings.allowAPIQuoteEdit) ||
    (hasContractedRate && !contractedRatesLocked)
  )
}

export const generateCharge = (base?: Partial<ChargeModel>): ChargeModel => ({
  ...emptyCharge,
  ...base,
  id: generateString(10),
})

export const getAmount = (model: Model) => {
  return model.charges?.length
    ? sum(model.charges.map(c => c.amount))
    : model.amount
}

export const modelToDto = (model: Model): UpsertOpportunityQuoteDto => ({
  ...model,
  amount: getAmount(model),
  charges: model.charges?.map(({ id, ...charge }) => charge),
})
