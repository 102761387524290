import clsx from 'clsx'
import dayjs from 'dayjs'

import { Icon } from '@fv/client-components'

type Message = {
  author: {
    email?: string
    name?: string
    role: string
  }
  createdDate: string
  messageText: string
}
type Props = {
  message: Message
}

export const MessageRow = ({ message }: Props) => {
  const { author, createdDate, messageText } = message
  const isSystemMessage = !author.name && !author.email

  return (
    <div
      className={clsx('speech-bubble pt-8', {
        'speech-bubble--me': author.role === 'carrier',
        'speech-bubble--them': author.role !== 'carrier',
      })}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: messageText?.replace(/\n/g, '<br/>'),
        }}
      />

      <div className="time-stamp--speech-bubble text-xs leading-[1.4rem]">
        <Icon
          icon={isSystemMessage ? 'server' : 'user-circle'}
          className="color-dark"
        />
        <span className="messages-username">
          {isSystemMessage ? 'System' : (author.name ?? author.email)}
        </span>
        <span>/</span>
        <span>{formatDate(createdDate)}</span>
      </div>
    </div>
  )
}

function formatDate(date: string) {
  const dayjsDate = dayjs(date)

  if (dayjsDate.isAfter(dayjs().startOf('day'))) {
    return `Today @ ${dayjsDate.format('h:mm a')}`
  } else if (dayjsDate.isAfter(dayjs().startOf('day').subtract(1, 'day'))) {
    return `Yesterday @ ${dayjsDate.format('h:mm a')}`
  }

  return `${dayjsDate.format('MMM Do')} @ ${dayjsDate.format('h:mm a')}`
}
