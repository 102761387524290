import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'
import { useFormModel, useFormModelState } from '@fv/client-core'
import { type PropsWithClassName } from '@fv/client-types'
import { type OpportunityDetailsDto } from '@fv/models'
import { type Opportunity } from '@/types/Opportunity'

import { useUpdateOppDetails } from './mutations'
import { createDetailModelFromOpp } from './utils'

type Props = PropsWithClassName<{
  opportunity: Opportunity
  onSubmit?: () => void
}>

export const AdditionalTruckInfoForm = ({
  opportunity,
  onSubmit,
  className,
}: Props) => {
  const updateOppDetails = useUpdateOppDetails()
  const { value, setValue } = useFormModelState({
    initialValue: createDetailModelFromOpp(opportunity),
  })
  const handleSubmit = async () => {
    await updateOppDetails.mutateAsync({
      loadId: opportunity.loadId,
      details: value,
    })
    onSubmit?.()
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <div className={`${className} flex items-end gap-x-2`}>
        <AdditionalTruckInfoFields
          value={value}
          onChange={setValue}
          className={className}
        />

        <FvButton
          icon="check"
          type="submit"
          theme="default"
          loading={updateOppDetails.isLoading}
        >
          Save
        </FvButton>
      </div>
    </ValidatedForm>
  )
}

type FieldProps = PropsWithClassName<{
  value: OpportunityDetailsDto
  onChange: (value: OpportunityDetailsDto) => void
}>
export const AdditionalTruckInfoFields = ({
  className,
  value,
  onChange,
}: FieldProps) => {
  const { register } = useFormModel({
    value,
    onChange,
  })
  return (
    <>
      <div className="form-group mb-0 basis-1/4">
        <label htmlFor="" className="label">
          Carrier name
        </label>

        <TextField
          className="form-control"
          maxLength={50}
          type="text"
          {...register('carrierName')}
        />
      </div>
      <div className="form-group basis-1/4 mb-0">
        <label htmlFor="" className="label">
          Tracking
        </label>
        <div className="input-group input-group--flex ">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextField
            className="form-control"
            maxLength={50}
            type="text"
            {...register('trackingNumber')}
          />
        </div>
      </div>
      <div className="form-group mb-0 basis-1/4">
        <label htmlFor="" className="label">
          Trailer
        </label>
        <div className="input-group input-group--flex">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextField
            {...register('trailerNumber')}
            className="form-control"
            maxLength={50}
            type="text"
          />
        </div>
      </div>

      <div className="form-group mb-0 basis-1/4">
        <label htmlFor="" className="label">
          Seal
        </label>
        <div className="input-group input-group--flex ">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextField
            {...register('sealNumber')}
            className="form-control"
            maxLength={50}
            type="text"
          />
        </div>
      </div>
    </>
  )
}

type ToggleProps = {
  value: boolean
  onToggle: () => void
}
export const AdditionalTruckInfoToggle = ({ value, onToggle }: ToggleProps) => {
  return (
    <FvButton icon={value ? 'minus' : 'plus'} onClick={onToggle}>
      <span className="b550:hidden">Add truck info (optional)</span>
      <span className="hidden b550:inline-block">Truck info</span>
    </FvButton>
  )
}
