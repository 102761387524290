import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type OpportunityDetailsDto } from '@fv/models'
import { useCacheHelpers } from '@/hooks/opportunities'
import { type Opportunity } from '@/types/Opportunity'
import { carrierFetch } from '@/utils/carrierFetch'

import { createDetailModelFromOpp } from './utils'

interface DetailOpts {
  loadId: string
  details: OpportunityDetailsDto
}
const putOppDetails = async ({ loadId, details }: DetailOpts) =>
  await carrierFetch<Opportunity>(`/loads/${loadId}/details`, {
    method: 'PUT',
    body: details,
  })

export const useUpdateOppDetails = () => {
  return useMutation(putOppDetails)
}

export const useUpdateOppDetailsHelpers = (opportunity: Opportunity) => {
  const baseMutation = useUpdateOppDetails()
  const { updateOppCaches } = useCacheHelpers()

  const useMutationHelper = (
    prop: keyof OpportunityDetailsDto,
    label: string,
  ) =>
    useMutation(
      async (value: string) =>
        await baseMutation.mutateAsync({
          loadId: opportunity.loadId,
          details: {
            ...createDetailModelFromOpp(opportunity),
            [prop]: value,
          },
        }),
      {
        onSuccess: updatedOpp => {
          updateOppCaches(updatedOpp, ['confirmed'])
          toast.success(`Updated ${label}`)
        },
        onError() {
          toast.success(`Error updating ${label}`)
        },
      },
    )

  return {
    updateTrailerNumber: useMutationHelper('trailerNumber', 'truck number'),
    updateSealNumber: useMutationHelper('sealNumber', 'seal number'),
    updateTrackingNumber: useMutationHelper(
      'trackingNumber',
      'tracking number',
    ),
  }
}
