import { ProfileForm } from './ProfileForm'

export const ProfileModalView = () => (
  <div className="items-center justify-center">
    <p>
      Please fill out your first name, last name and your job role. You only
      have to do this once.
    </p>
    <hr className="mt-2 mb-4" />
    <ProfileForm />
  </div>
)
