import { type ChangeEvent, type PropsWithChildren, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import logo from '@fv/client-assets/freightview.svg'
import {
  FvButton,
  FvDate,
  Icon,
  InputGroup,
  TextField,
  ValidatedForm,
} from '@fv/client-components'

import { useSetPickupNumber } from './mutations'
import { usePublicShipment } from './queries'

export const PickupNumberForm = () => {
  const [localPickupNum, setLocalPickupNum] = useState('')
  const { loadId = '' } = useParams<{ loadId: string }>()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')
  const setPickupNum = useSetPickupNumber(loadId, token)
  const shipmentQuery = usePublicShipment(loadId, token)

  if (shipmentQuery.isLoading) {
    return (
      <div className="PickupNumberForm_container">
        <div>
          <Icon icon="spinner" size="2x" />
          <span style={{ fontSize: '2rem' }}>Loading...</span>
        </div>
      </div>
    )
  }

  if (shipmentQuery.isError && !shipmentQuery.data) {
    return (
      <div className="PickupNumberForm_container">
        <Icon className="color-warning" icon="exclamation-triangle" size="3x" />
        <span style={{ fontSize: '2rem', textAlign: 'center' }}>
          {(shipmentQuery.error as any)?.message} Please contact our support
          team at{' '}
          <a href="mailto:support@freightview.com">support@freightview.com</a>{' '}
          for assistance.
        </span>
      </div>
    )
  }

  const {
    bolNumber,
    locations = [],
    pickupConfirmationNumber,
    pickupDate,
  } = shipmentQuery.data ?? {}

  const origin = locations[0]
  const dest = locations[locations.length - 1]

  return (
    <div className="mt-10 flex flex-col items-center justify-center">
      <div className="flex max-w-md flex-col items-start gap-6 px-2">
        <h1 className="flex gap-4 self-center text-4xl">
          <a href="https://www.freightview.com">
            <img src={logo} alt="Freightview" />
          </a>{' '}
          Confirm Pickup
        </h1>
        <p className="text-center">
          Please confirm the details and submit the pickup confirmation number
          for this shipment.
        </p>
        <div className="flex flex-col items-start text-lg">
          <DetailRow label="BOL#">{bolNumber || 'NA'}</DetailRow>
          <DetailRow label="Origin">
            {origin.city}, {origin.state} {origin.postalCode}
          </DetailRow>

          <DetailRow label="Destination">
            {dest.city}, {dest.state} {dest.postalCode}
          </DetailRow>

          <DetailRow label="Pickup date">
            <FvDate val={pickupDate} format="MM/DD/YYYY" />
          </DetailRow>

          {pickupConfirmationNumber && (
            <DetailRow label="Pickup confirmation number">
              {pickupConfirmationNumber}
            </DetailRow>
          )}
        </div>

        {pickupConfirmationNumber ? (
          <div className="flex flex-col items-center text-center">
            <Icon className="color-success" icon="check" size="5x" />
            <span className="text-lg">
              Thank you for entering a pickup confirmation number. You can now
              close this window.
            </span>
          </div>
        ) : (
          <ValidatedForm
            className="flex flex-col items-start"
            onValidSubmit={() => {
              if (setPickupNum.isLoading) return
              setPickupNum.mutate(localPickupNum)
            }}
          >
            <InputGroup
              label="Pickup confirmation number"
              className="my-4 w-full items-start"
            >
              <TextField
                className="form-control mb-4"
                disabled={setPickupNum.isLoading}
                id="pickupConfirmationNumber"
                name="pickupConfirmationNumber"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLocalPickupNum(e.target.value)
                }}
                placeholder="i.e. 87384276"
                required
                value={localPickupNum}
              />
            </InputGroup>

            <FvButton
              type="submit"
              icon="check"
              loading={setPickupNum.isLoading}
            >
              Submit
            </FvButton>
          </ValidatedForm>
        )}
      </div>
      <div className="mt-20 flex flex-wrap rounded-md border border-gray-100 bg-[#F5FEFF] px-4 py-2 text-center text-[#666]">
        Freightview -- 901 Carondelet Dr - Kansas&nbsp;City,&nbsp;MO&nbsp;64114
      </div>
    </div>
  )
}

type DetailProps = PropsWithChildren<{
  label: string
}>

const DetailRow = ({ label, children }: DetailProps) => {
  return (
    <div>
      <span className="mr-2 font-bold">{label}:</span> {children}
    </div>
  )
}
