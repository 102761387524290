import { type SelectFieldOption } from '@fv/client-types'
import {
  type ChargeName,
  type UpsertOppChargeDto,
  type UpsertOpportunityQuoteDto,
} from '@fv/models'
import { type Opportunity } from '@/types/Opportunity'

export interface ChargeModel extends UpsertOppChargeDto {
  id: string
}
export interface Model extends Omit<UpsertOpportunityQuoteDto, 'charges'> {
  charges?: ChargeModel[]
  errors?: Record<keyof UpsertOpportunityQuoteDto, string>
}

export interface QuotesFormState {
  message: string
  opportunity: Opportunity
  models: Model[]
  equipmentOptions: SelectFieldOption[]
  selected: string[]
  contractedRatesLocked: boolean
}

export interface QuoteFormActions {
  setMessage: (message: string) => void
  resetModels: (models: Model[]) => void
  updateModel: (model: Model) => void
  toggleSelected: (id: string, toggle: boolean) => void
  toggleContractedRateLock: () => void
  setContractedRateLock: (contractedRatesLocked: boolean) => void
}

export interface QuotesFormStore extends QuotesFormState {
  actions: QuoteFormActions
}

export const emptyCharge: ChargeModel = {
  id: '',
  name: '' as ChargeName,
  amount: 0,
}
