import { OppFilterPanel } from './FilterPanel'
import { OppListing } from './OppListing'
import { OppListingProvider } from './OppListingContextProvider'
import { OppMessaging } from './OppMessaging'
import { type oppPageStatusMap } from './types'

type Mode = (typeof oppPageStatusMap)['active'][number]
type Props = {
  mode?: Mode
}
export const ActivePage = ({ mode }: Props) => {
  return (
    <OppListingProvider status={mode ?? 'confirmed'} page="active">
      <OppFilterPanel
        items={[
          { label: 'Active', icon: 'truck-moving', to: '/active' },
          { label: 'Archived', icon: 'archive', to: '/active/archived' },
        ]}
      />
      <OppListing
        emptyText={`There are no ${mode ?? 'confirmed'} shipments in your list`}
      />
      <OppMessaging />
    </OppListingProvider>
  )
}
