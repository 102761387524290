import { type UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'

import { FvButton, TextField, ValidatedForm } from '@fv/client-components'

type DisplayProps = {
  label: string
  value?: string
  showEmpty?: boolean
  readonly?: boolean
  onClick?: () => void
}
export const RefNumDisplayToggle = (props: DisplayProps) => {
  const { readonly, label, value, showEmpty, onClick } = props
  if (!showEmpty && !value) return null

  return (
    <span>
      {label}&nbsp;
      {readonly && value}
      {!readonly && (
        <FvButton theme="underlined" onClick={onClick}>
          {value || 'NA'}
        </FvButton>
      )}
    </span>
  )
}

type FormProps = {
  initialValue: string
  name: string
  label: string
  onToggle: () => void
  mutation: UseMutationResult<unknown, unknown, string, unknown>
}
export const RefNumForm = (props: FormProps) => {
  const { initialValue, mutation, name, label, onToggle } = props
  const [value, setValue] = useState(initialValue)
  const isLoading = mutation.isLoading
  const handleSubmit = async () => {
    await mutation.mutateAsync(value)
    onToggle()
  }

  return (
    <ValidatedForm className="flex items-center" onValidSubmit={handleSubmit}>
      <TextField
        autoFocus
        className="form-control"
        value={value}
        onChange={e => setValue(e.target.value)}
        name={name}
        readOnly={isLoading}
        required
        type="text"
      />

      <FvButton
        loading={isLoading}
        icon="check"
        theme="default"
        type="submit"
        className="-ml-px"
      >
        {label}
      </FvButton>
      <FvButton icon="times" onClick={onToggle} theme="plain" />
    </ValidatedForm>
  )
}
