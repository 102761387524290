import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import { Icon, Tooltip } from '@fv/client-components'
import { type Quote } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'
import { CheckboxInput } from '@/components/inputs/CheckboxInput'
import { SelectInput } from '@/components/inputs/SelectInput'
import { TextInput } from '@/components/inputs/TextInput'
import { useAppSettings } from '@/hooks/settings'

import { BaseRateField } from './BaseRateField'
import { ChargeBreakdownControls } from './ChargeBreakdownControls'
import {
  useQuotesFormActions,
  useQuotesFormHelpers,
  useQuotesFormState,
} from './QuotesFormProvider'
import { type Model } from './types'

type QuoteProps = Pick<Quote, 'method' | 'source'>
type Props = {
  value: Model
  disabled?: boolean
  loading?: boolean
  quote?: QuoteProps
}
export const QuoteFormEntry = ({ value, loading, disabled, quote }: Props) => {
  const amountRef = useRef<HTMLInputElement>(null)
  const numRef = useRef<HTMLInputElement>(null)
  const settings = useAppSettings().data
  const quoteType = quoteTypeLabel(quote)
  const { equipmentOptions, selected, contractedRatesLocked } =
    useQuotesFormState()
  const { updateModel, toggleSelected } = useQuotesFormActions()
  const { switchFromCharges } = useQuotesFormHelpers()
  const { method, source } = quote ?? {}
  const isApiRate = method === 'api'
  const isCarrierRate = !quote || (method === 'manual' && source === 'carrier')
  const isContractedRate = method === 'upload'
  const isShipperRate = method === 'manual' && source === 'shipper'
  const isSelected = selected.includes(value.quoteId)
  const inputName = (name: string) => `${value.quoteId ?? ''}-${name}`

  useEffect(() => {
    amountRef.current?.setCustomValidity(value.errors?.amount ?? '')
    numRef.current?.setCustomValidity(value.errors?.quoteNum ?? '')
  }, [value.errors])

  const handleToggleSelected = (toggle: boolean) => {
    if (!toggle) {
      updateModel({
        ...value,
        quoteNum: '',
        amount: 0,
      })
    }
    toggleSelected(value.quoteId, toggle)
  }
  const handleUpdate = (update: Model) => {
    const needsSwitched =
      value.charges?.length > 1 && update.charges?.length <= 1

    updateModel(update)
    needsSwitched && switchFromCharges(update)
  }
  return (
    <div>
      {isContractedRate && !contractedRatesLocked && (
        <div className="box mt-4 bg-white">
          <div className="icon-message">
            <Icon
              className="text-fv-orange"
              icon="hand-paper"
              transform="down-1"
            />
            <p className="mb-0">
              This is a contracted rate. Your company has negotiated a
              pre-determined rate for this lane. Unlocking and modifying this
              rate will override your contracted rate. The shipper will then be
              shown the new rate. Select 'Update bid' to proceed or 'X' to
              cancel and keep the contracted rate.
            </p>
          </div>
        </div>
      )}

      <div
        className={clsx(
          'b850:flex-wrap b1500:flex-wrap b1000:flex-nowrap my-4 flex items-center gap-y-3 gap-x-2',
        )}
      >
        <div className="flex items-center gap-x-2 gap-y-3">
          <CheckboxInput
            checked={isSelected}
            className={clsx('checkbox', {
              disabled: loading || !isCarrierRate,
            })}
            disabled={loading || !isCarrierRate}
            label={quoteType + ' quote'}
            name={inputName(`selected`)}
            onChange={e => handleToggleSelected(e.target.checked)}
            required={!quote}
          />

          {isShipperRate && (
            <Tooltip label="This quote was entered by the shipper">
              <Icon icon="user-circle" />
            </Tooltip>
          )}

          {isContractedRate && (
            <Tooltip label="This is a contracted rate">
              <Icon icon="money-check-alt" transform="down-.5" />
            </Tooltip>
          )}

          {isApiRate && (
            <Tooltip label="Automatically generated via API">
              <Icon icon="server" />
            </Tooltip>
          )}
        </div>

        <div className="h-0 flex-1 border border-dashed border-b-[#45abd3] b1500:basis-1/2" />

        {equipmentOptions.length > 1 && (
          <SelectInput
            className="w-auto"
            value={value.equipmentType}
            disabled={disabled}
            name={inputName(`equip-type`)}
            onChange={e => {
              handleUpdate({
                ...value,
                equipmentType: e.target.value as EquipmentType,
              })
            }}
            options={equipmentOptions}
          />
        )}

        <div className="input-group input-group--flex mb-0">
          <div className="input-group-addon">
            <Icon icon="hashtag" className="color-dark" />
          </div>
          <TextInput
            className="form-control w-40"
            value={value.quoteNum}
            ref={numRef}
            disabled={disabled}
            maxLength={50}
            name={inputName(`num`)}
            onChange={e => {
              handleToggleSelected(true)
              handleUpdate({ ...value, quoteNum: e.target.value })
            }}
            placeholder="Quote #"
            required={isSelected && settings.requiresQuoteNumber}
          />
        </div>

        <BaseRateField
          model={value}
          onChange={updates => {
            handleToggleSelected(true)
            handleUpdate(updates)
          }}
          required={isSelected}
          disabled={disabled}
          isContractedRate={isContractedRate}
        />
      </div>
      {value.charges?.length > 1 && (
        <ChargeBreakdownControls
          model={value}
          onChange={v => handleUpdate(v)}
        />
      )}
    </div>
  )
}

function quoteTypeLabel(quote?: QuoteProps) {
  if (quote?.method === 'api') return 'API'
  if (quote?.method === 'upload') return 'Contract'
  if (quote?.source === 'shipper') return 'Shipper'
  return 'Spot'
}
