import clsx from 'clsx'
import { useSearchParams } from 'react-router-dom'

import { FvLinkButton, Icon, type IconName } from '@fv/client-components'

import { ShipperSelector } from './ShipperSelector'

interface FilterItem {
  icon: IconName
  className?: string
  label: string
  to: string
}
type Props = {
  items: FilterItem[]
}
export const OppFilterPanel = ({ items }: Props) => {
  const [searchParams] = useSearchParams()
  return (
    <div className="bg-[#f2f9fc] pt-8 px-4 border-r border-r-fv-gray b1500:col-span-2 b1500:flex b1500:items-center b1500:pl-6 b1500:border-b b1500:border-fv-blue-300 b1500:pt-6 b1500:pb-3 overflow-auto b1000:border-r-0 b1000:col-span-3 row-span-2 b1500:row-span-1">
      <div className="b1500:mr-4 b1500:max-w-[16rem]">
        <ShipperSelector />
      </div>
      <div className="mt-8 mb-4 b1500:m-0 whitespace-nowrap">
        <Icon icon="filter" className="color-dark fa-fw" />
        <span>Filter</span>
      </div>

      <ul className="divide-y divide-[#bde1ef] border-t border-b border-[#bde1ef] mt-2 -mx-4 b1500:flex b1500:items-center b1500:ml-2 b1500:mr-4 b1500:mt-0 b1500:border-none b1500:divide-x b1500:divide-y-0 whitespace-nowrap">
        {items.map((i, ix) => (
          <li key={ix}>
            <FvLinkButton
              end
              icon={i.icon}
              className={({ isActive }) =>
                clsx(
                  'p-4 block b1500:px-2 b1500:py-0 b1500:inline',
                  i.className,
                  {
                    'bg-fv-beer b1500:bg-transparent [&>svg]:text-fv-orange pointer-events-none ':
                      isActive,
                  },
                )
              }
              to={{
                pathname: i.to,
                search: searchParams.toString(),
              }}
            >
              {i.label}
            </FvLinkButton>
          </li>
        ))}
      </ul>
    </div>
  )
}
