import orderBy from 'lodash/orderBy'

import { type SelectFieldOption } from '@fv/client-types'
import { SelectInput } from '@/components/inputs/SelectInput'
import { useUserContext } from '@/hooks/settings'

import { useListingQueryParams } from './hooks'

export const ShipperSelector = () => {
  const { isLoading, data } = useUserContext()
  const [params, setParams] = useListingQueryParams()
  const shippers: SelectFieldOption[] = [
    { text: 'All shippers', value: '' },
    ...orderBy(
      data.user.shippers.map(s => ({ text: s.name, value: s.accountId })),
      v => v.text.toLowerCase(),
    ),
  ]
  if (isLoading || !data) return null

  return (
    <SelectInput
      name="shipper"
      className="truncate"
      options={shippers}
      value={params.accountId ?? ''}
      onChange={e =>
        setParams(p => ({
          ...p,
          accountId: e.target.value ? e.target.value : undefined,
        }))
      }
    />
  )
}
