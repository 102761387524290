import { Children, type PropsWithChildren } from 'react'

export const SlashSeparated = ({ children }: PropsWithChildren) => {
  const arrayChildren = Children.toArray(children)
  return (
    <>
      {Children.map(arrayChildren, (child, index) => {
        return (
          <>
            {index !== 0 && (
              <span className="slash first:hidden last:hidden [&+.slash]:hidden">
                &nbsp;/&nbsp;
              </span>
            )}
            {child}
          </>
        )
      })}
    </>
  )
}
