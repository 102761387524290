import clsx from 'clsx'
import { type To } from 'react-router-dom'

import { FvNavLink, Icon } from '@fv/client-components'
import { useUserContext } from '@/hooks/settings'

import { useListingQueryParams } from '../opportunities/hooks'
import { NumberInBucket } from './NumberInBucket'

const getLinkClass = ({ isActive }: { isActive: boolean }) => {
  return clsx(
    'py-4 pr-4 pl-4 flex relative border-l border-r border-fv-gray-300 -ml-px [&>svg]:text-fv-blue group whitespace-nowrap',
    {
      'after:absolute after:-bottom-2 after:left-1/2 bg-[#e3f8ff] border-l border-r border-gray-400 after:w-4 after:h-4 after:bg-#f2ffea after:-translate-x-1/2 after:rotate-45 after:bg-[#e3f8ff] after:border after:border-gray-400 after:border-l-transparent after:border-t-transparent z-10 pointer-events-none [&>svg]:!text-fv-orange before:absolute before:w-2 before:h-2 before:bg-fv-orange before:-bottom-1 before:left-1/2 before:rotate-45 before:-translate-x-1/2 before:z-20':
        isActive,
    },
  )
}

export const Nav = () => {
  const context = useUserContext()
  const [params] = useListingQueryParams()

  const maintainShipperFilter = (to: string): To => {
    return {
      pathname: to,
      search: params.accountId ? `?accountId=${params.accountId}` : '',
    }
  }

  return (
    <nav className="main-nav flex items-stretch bg-[#f9fdff] border-b border-fv-gray-500 [&>*]:last:ml-auto col-span-3 b750:text-lg pr-2">
      <FvNavLink className={getLinkClass} to="/search">
        <Icon
          icon="search"
          className="fa-fw color-dark group-hover:text-black"
        />
      </FvNavLink>

      <FvNavLink
        className={getLinkClass}
        to={maintainShipperFilter('/opportunities')}
      >
        <Icon
          icon="bullseye-arrow"
          className="fa-fw color-dark group-hover:text-black"
        />
        <span className="b750:hidden">Opportunities</span>
        <NumberInBucket status="open" className="b500:hidden" />
      </FvNavLink>

      <FvNavLink
        className={getLinkClass}
        to={maintainShipperFilter('/quoting')}
      >
        <Icon
          icon="badge-dollar"
          className="fa-fw group-hover:text-black"
          transform="up-1"
        />

        <span className="b1150:hidden">Currently quoting</span>
        <span className="hidden b1150:block b750:hidden">Quoting</span>

        <NumberInBucket status="offered" className="b500:hidden" />
      </FvNavLink>

      <FvNavLink className={getLinkClass} to={maintainShipperFilter('/won')}>
        <Icon
          icon="trophy"
          className="fa-fw group-hover:text-black"
          transform="up-1"
        />
        <span className="b1150:hidden">Shipments won</span>
        <span className="hidden b1150:block b750:hidden">Won</span>
        <NumberInBucket status="awarded" className="b500:hidden" />
      </FvNavLink>

      <FvNavLink className={getLinkClass} to={maintainShipperFilter('/active')}>
        <Icon
          icon="truck"
          className="fa-fw group-hover:text-black"
          transform="up-1"
        />
        <span className="b1150:hidden">Active shipments</span>
        <span className="hidden b1150:block b750:hidden">Active</span>

        <NumberInBucket status="confirmed" className="b500:hidden" />
      </FvNavLink>

      <FvNavLink
        className="standard-link standard-link--side-nav-link ml-auto bg-transparent overflow-hidden"
        to="/settings"
      >
        <Icon icon="cog" className="fa-fw color-dark" />
        <span className="nav-username-text">{context.data?.user?.email}</span>
      </FvNavLink>
    </nav>
  )
}
