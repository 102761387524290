import { Icon } from '@fv/client-components'

export const Loading = () => {
  return (
    <span>
      <Icon icon="sync" fixedWidth />
      Loading
    </span>
  )
}
